import amplitude from 'amplitude-js';
import {APP_NAME, STUDIO_DOCS_APP_NAME} from "../constants";
import {getUrlParams, isStaging, updateCookie} from "../utils/common";

export function initAmplitude() {
    const AMPLITUDE_API_KEY = isStaging() ? "8a79e0c614753d8f3787c537ecef49d0" : "be0223327acdb089744156d19857f1ac";
    let instance = amplitude.getInstance();
    if (instance && instance.options && instance.options.deviceId) {
        console.log('lp-existing device id: ', instance.options.deviceId);
        return;
    }

    instance.init(AMPLITUDE_API_KEY, undefined, {
        includeUtm: true,
        includeReferrer: true,
        onExitPage: () => {amplitude.getInstance().sendEvents()}
    });
    instance = amplitude.getInstance();
    setTimeout(() => updateCookie({lpDeviceId: instance.options.deviceId}), 100);
    console.log('lp-deviceId: ', instance.options.deviceId);
}


export function logEvent(type, props) {
    try {
        initAmplitude();
        const urlParams = getUrlParams();
        amplitude.getInstance().logEvent(type, {
            ...props,
            ...urlParams,
            "app": APP_NAME,
            "host": window.location.hostname,
            "path": window.location.pathname,
			"referrer": document.referrer
        });
    } catch (e) {
        console.log('%cerror', 'color: red; font-size: xx-large', e);
    }
}

export function studioDocslogEvent(type, props) {
    try {
        initAmplitude();
        amplitude.getInstance().logEvent(type, {
            ...props,
            "app": STUDIO_DOCS_APP_NAME,
            "host": window.location.hostname,
        });
    } catch (e) {
        console.log('%cerror', 'color: red; font-size: xx-large', e);
    }
}


export function setUserProperty(key, value) {
    const identify = new amplitude.Identify().set(key, value);
    amplitude.getInstance().identify(identify);
}
